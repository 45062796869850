(<template>
  <section class="buyer-video-details">
    <slot></slot>
    <div v-if="videoroomJob"
         class="buyer-video-details__data-cont buyer-video-details__data-cont--white">
      <conference-systems />
      <skype-data class="buyer-video-details__skype-data" />
    </div>
    <div v-if="videoJob"
         class="buyer-video-details__data-cont">
      <video-service />
    </div>
  </section>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import VideoService from '@/components/assignment_components/one_assignment/awarded_assignment/shared_components/VideoService';
  import ConferenceSystemsData from '@/components/assignment_components/one_assignment/awarded_assignment/shared_components/ConferenceSystemsData';
  import BusinessSkypeData from '@/components/assignment_components/one_assignment/awarded_assignment/shared_components/BusinessSkypeData';

  export default {
    components: {
      'video-service': VideoService,
      'conference-systems': ConferenceSystemsData,
      'skype-data': BusinessSkypeData
    },
    computed: {
      ...mapGetters({
        userCanUseChat: 'UserInfoStore/userCanUseChat'
      }),
      ...mapState('OneAssignmentStore', {
        assignmentRequirements: (state) => state.storeJobObj.interpretationRequirement || {},
        contractInfo: (state) => {
          return state.jobCallDetails && state.jobCallDetails.contractInfo ? state.jobCallDetails.contractInfo : [];
        }
      }),
      videoJob() { return this.assignmentRequirements.sessionType == 'video'; },
      videoroomJob() { return this.assignmentRequirements.sessionType == 'videoroom'; }
    }
  };
</script>

<style scoped>
  .buyer-video-details {
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .buyer-video-details__data-cont {
    margin: 0 -30px;
    padding: 0 30px;
    border-top: 20px solid white;
    background: #e9ebef;
  }

  .buyer-video-details__data-cont--white {
    background-color: #fff;
  }

  .buyer-video-details__skype-data {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .buyer-video-details__data-cont {
      margin: 0 -15px;
      padding: 0 15px;
    }
  }
</style>
