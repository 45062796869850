(<template>
  <section class="skype-data">
    <h3 class="skype-data__title">{{ $gettext('Skype for Business') }}</h3>
    <div class="skype-data__data-wrapper">
      <section class="skype-data__data-section">
        <h4 class="skype-data__data-title">{{ $gettext('Skype for Business/Lync:') }}</h4>
        <p class="skype-data__data-text">{{ conference }}</p>
      </section>
      <section v-if="pin"
               class="skype-data__data-section">
        <h4 class="skype-data__data-title">{{ $gettext('Pin code:') }}</h4>
        <p class="skype-data__data-text">{{ pin }}</p>
      </section>
    </div>
  </section>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    computed: {
      ...mapState('OneAssignmentStore', {
        sessionDetails: (state) => {
          return state.jobCallDetails && state.jobCallDetails.sessionDetails ? state.jobCallDetails.sessionDetails : {};
        }
      }),
      pin() {
        return this.sessionDetails.pin || '';
      },
      conference() {
        return this.sessionDetails.conference || '';
      }
    }
  };
</script>

<style scoped>
  .skype-data {
    display: block;
    width: 220px;
    padding-right: 50px;
    font-size: 12px;
  }

  .skype-data__title {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    color: #7a829d;
    font-weight: normal;
  }

  .skype-data__data-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .skype-data__data-section {
    display: block;
    padding-right: 25px;
  }

  .skype-data__data-section:first-of-type {
    width: 100%;
    margin-bottom: 10px;
  }

  .skype-data__data-title {
    display: block;
    width: 100%;
    color: #7a829d;
    font-weight: normal;
    font-size: 10px;
  }

  .skype-data__data-text {
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
  }

  @media (max-width: 1024px) {
    .skype-data {
      width: 100%;
      padding: 10px 15px;
      border-bottom: 1px solid #e9eaef;
    }

    .skype-data__title {
      margin-bottom: 0;
    }

    .skype-data__data-section,
    .skype-data__data-section:first-of-type {
      width: auto;
      margin-bottom: 0;
      padding-top: 10px;
      padding-right: 10px;
    }
  }
</style>
